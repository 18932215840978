import Gear from 'components/icons/Gear';
import Calc from 'components/icons/MathSigns';
import Rocket from 'components/icons/Rocket';
import Vine from 'components/icons/Vine';
import Wing from 'components/icons/Wing';
import LineHeading from 'components/LineHeading';
import { H4 } from 'components/typography/headings';
import {
  withParallaxProvider,
  ParallaxChild,
  ParallaxContainer,
} from 'hooks/useParallax';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'system';
import { Column, Container, Row, Section } from 'system/layout';
import * as A from 'system/animated';
import { Box } from 'system';
import { animated } from 'react-spring';
import { useWindowResize } from 'hooks/index';
const icons = {
  rocket: Rocket,
  math: Calc,
  wing: Wing,
  gear: Gear,
  vine: Vine,
};

const CUT_HEIGHT = [75, 120, 170, 200];
const CUT_HEIGHT_BOTTOM = [75, 130, 170];

function BG({ theme, scrollTop, sizeRef }) {
  // to the top points animation based on the scroll position
  const pts = scrollTop.to(st => {
    const pos = Math.max(Math.round(st * 0.1), 0);
    return `0,${pos} 100,100 0,100`;
  });

  // to the bottom points animation based on the scroll position
  const ptsBottom = scrollTop.to(st => {
    const pos = Math.min(
      Math.max(Math.round((st + bgHeight + 200) * 0.1), 0),
      100
    );
    return `0,0 0,${pos} 100,0`;
  });

  // gets the height of the middle yellow section based on the height of the content in the block
  const [bgHeight, setBgHeight] = useState(
    sizeRef.current ? sizeRef.current.getBoundingClientRect().height : 0
  );

  // reset the height of the middle yellow section when the block changes height
  const onWindowResize = useCallback(() => {
    if (!sizeRef.current) {
      return;
    }
    setBgHeight(sizeRef.current.getBoundingClientRect().height);
  }, [setBgHeight, sizeRef]);
  useEffect(onWindowResize, [sizeRef]);
  useWindowResize(onWindowResize);

  return (
    <A.Absolute
      zIndex={0}
      // top={CUT_HEIGHT.map(value => -value)}
      top={[-40, -40, -70]}
      left={0}
      width={1}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.yellow} points={pts} />
        </Box>
      </Box>
      <Box
        width={1}
        bg={theme.colors.yellow}
        height={[bgHeight, bgHeight * 0.97, bgHeight * 0.9]}
      />
      <Box width={1} height={CUT_HEIGHT_BOTTOM}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.yellow} points={ptsBottom} />
        </Box>
      </Box>
    </A.Absolute>
  );
}

function AboutValuesAndBeliefsBlock({
  data: { valuesBeliefsCallout, valuesBeliefsListing },
  theme,
  parallax,
  ref,
}) {
  const bgRef = useRef();
  return (
    <Section ref={ref}>
      <BG scrollTop={parallax.scrollTop} theme={theme} sizeRef={bgRef} />
      <Container zIndex={1} ref={bgRef}>
        <ParallaxContainer as={Row} normal={[0.25, 0.25, 0.15]}>
          <Column
            width={[1, 1, 11 / 12, 11 / 12]}
            childMarkdownRemark={[
              `${(1 / 2) * 100}%`,
              `${(1 / 2) * 100}%`,
              `${(1 / 12) * 100}%`,
              `${(1 / 12) * 100}%`,
            ]}
          >
            <LineHeading
              label={valuesBeliefsCallout}
              direction={`rtl`}
              mb={50}
              offsetY={0.05}
            />
          </Column>
        </ParallaxContainer>
        <Row>
          {valuesBeliefsListing.map((listing, idx) => {
            const Icon = icons[listing.icon];
            return (
              <ParallaxContainer
                as={Column}
                normal={[0.25, 0.25, 0.15]}
                key={`value-${idx}`}
                width={[1, 1, 5 / 12]}
                mr={[0, 0, `${(1 / 12) * 100}%`]}
                mb={[20, 30, 20]}
                offsetY={0.025 + idx * 0.025}
              >
                <ParallaxChild
                  width={[1 / 7, 1 / 8, 1 / 5, 1 / 6]}
                  pr={20}
                  offsetY={0.05}
                  // mt={listing.icon === `rocket` ? -10 : null}
                  // height={[listing.icon === `rocket` ? `75%` : null]}
                >
                  <Icon title={listing.icon} />
                </ParallaxChild>
                <ParallaxChild
                  width={[6 / 7, 7 / 8, 4 / 5, 5 / 6]}
                  offsetY={0.025}
                >
                  <H4 mb={[10, 20, 30]}>{listing.heading}</H4>
                  <Text
                    fontSize={[14, 16, 18]}
                    dangerouslySetInnerHTML={{
                      __html: listing.descriptionNode.childMarkdownRemark.html,
                    }}
                  />
                </ParallaxChild>
              </ParallaxContainer>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
}

export default withTheme(withParallaxProvider(AboutValuesAndBeliefsBlock));
