import React, { useState, useRef, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease';
function Star({
  title,
  fill,
  theme,
  autoplay = true,
  timeout = 0,
  delay = 0,
  style,
  ...rest
}) {
  const delayRef = useRef(delay);
  const delayTimeoutRef = useRef(0);
  const timeoutRef = useRef(0);
  const [animating, setAnimating] = useState(autoplay && delay === 0);
  const isAnimating = useRef(autoplay && delay === 0);
  const isStopped = useRef(delay > 0);

  const [spring, set] = useSpring(() => ({
    config: {
      duration: 1500,
    },
    r: 0,
    onRest: ({ r }) => {
      if (isStopped.current) {
        return;
      }
      set({
        r: isAnimating.current ? r - 360 : r - 180,
        config: {
          duration: isAnimating.current ? 1500 : 1000,
          easing: isAnimating.current ? easings.easeLinear : easings.easeSinOut,
        },
      });
      isStopped.current = isAnimating.current === false;
    },
  }));

  useEffect(() => {
    isAnimating.current = animating;
    if (animating && timeout > 0) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setAnimating(false);
      }, timeout);
    }
  }, [animating, timeout]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(delayTimeoutRef.current);
    };
  }, []);

  if (!autoplay && delayRef.current > 0) {
    delayTimeoutRef.current = setTimeout(() => {
      isStopped.current = false;
      isAnimating.current = true;
      setAnimating(true);
      set({
        r: -360,
        config: {
          duration: 1000,
          easing: easings.easeLinear,
        },
      });
    }, delayRef.current);
    delayRef.current = 0;
  }

  return (
    <animated.svg
      width="1em"
      height="1em"
      viewBox="0 0 40.394 40.394"
      style={{
        ...style,
        transformOrigin: `50% 50%`,
        transform: spring.r.to(r => `rotate(${r}deg)`),
      }}
      {...rest}
    >
      <title>{title}</title>
      <path
        d="M7.71 38.414l12.5-9.062 12.475 9.062-4.762-14.69 12.469-9.05H24.98L20.211-.001l-4.767 14.675H0l12.48 9.05z"
        fill={fill || theme.colors.blue}
      />
    </animated.svg>
  );
}

export default withTheme(Star);
