import React from 'react';
import { default as GImg } from './GatsbyImage';

const Img = React.forwardRef(
  ({ alt, style, className, imgStyle, ...props }, forwardedRef) => {
    const src = props.src || props.url;
    const attrs = { ...props, imgStyle, src };

    if (!props.fluid && !props.fixed) {
      return (
        <img
          src={src}
          alt={alt}
          className={className}
          style={{ ...imgStyle, ...style }}
          ref={forwardedRef}
        />
      );
    }
    return (
      <GImg
        {...attrs}
        alt={alt}
        style={style}
        ref={forwardedRef}
        className={className}
      />
    );
  }
);

export default Img;
