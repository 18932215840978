import React, { useState, useEffect, useRef } from 'react';
import { useSprings, config } from 'react-spring';
import { Flex, Box } from 'system/animated';
import { withTheme } from 'styled-components';

function AnimatedBars({
  theme,
  color = `yellow`,
  autoplay = true,
  timeout = 0,
  delay = 0,
  animateOnHover = true,
  ...rest
}) {
  const delayRef = useRef(delay);
  const timeoutRef = useRef(0);
  const delayTimeoutRef = useRef(0);
  const [animating, setAnimating] = useState(autoplay && delay === 0);
  const configs = useRef([config.gentle, config.slow]);
  const barColor = color.charAt(0) === `#` ? color : theme.colors[color];

  const [springs, set] = useSprings(3, () => ({
    y: Math.random() * 0.9 + 0.05,
    from: { y: Math.random() * 0.9 + 0.05 },
    config: { ...configs.current[Math.floor(Math.random() * 2)], clamp: true },
    onRest: () => {
      set(() => ({
        y: Math.random() * 0.9 + 0.05,
        config: { ...configs.current[Math.floor(Math.random() * 2)] },
      }));
    },
  }));

  useEffect(() => {
    if (animating && timeout > 0) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setAnimating(false);
      }, timeout);
    }
  }, [animating, timeout]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(delayTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!autoplay && delayRef.current > 0) {
      delayTimeoutRef.current = setTimeout(() => {
        setAnimating(true);
        set(() => ({
          y: Math.random() * 0.9 + 0.05,
          config: { ...configs.current[Math.floor(Math.random() * 2)] },
        }));
      }, delayRef.current);
    }
  }, [autoplay, set]);

  return (
    <Flex
      width={41}
      ml={10}
      mr={10}
      display={`inline-flex`}
      justifyContent={`space-between`}
      {...rest}
    >
      <Box
        height={37}
        width={12}
        bg={barColor}
        style={{
          transformOrigin: `50% 100%`,
          transform: animating
            ? springs[0].y.to(y => `scaleY(${y})`)
            : `scaleY(${springs[0].y.value})`,
        }}
      />
      <Box
        height={37}
        width={12}
        bg={barColor}
        style={{
          transformOrigin: `50% 100%`,
          transform: animating
            ? springs[1].y.to(y => `scaleY(${y})`)
            : `scaleY(${springs[1].y.value})`,
        }}
      />
      <Box
        height={37}
        width={12}
        bg={barColor}
        style={{
          transformOrigin: `50% 100%`,
          transform: animating
            ? springs[2].y.to(y => `scaleY(${y})`)
            : `scaleY(${springs[2].y.value})`,
        }}
      />
    </Flex>
  );
}

export default withTheme(AnimatedBars);
