import LineHeading from 'components/LineHeading';
import { H2, H3 } from 'components/typography/headings';
import { useWindowResize } from 'hooks/index';
import { ParallaxChild, withParallaxProvider } from 'hooks/useParallax';
import React from 'react';
import { animated } from 'react-spring';
import { withTheme } from 'styled-components';
import * as A from 'system/animated';
import { Box } from 'system';
import { Column, Container, Row, Section } from 'system/layout';
import Img from 'components/Img/index';

const CUT_HEIGHT = [60, 80, 100, 130];

function BG({ theme, scrollTop }) {
  // to the top points animation based on the scroll position
  const pts = scrollTop.to(st => {
    const pos = Math.max(60 - Math.round(st * 0.1), 0);
    // console.log(pos);
    return `100,${pos} 100,100 0,100`;
  });

  // the useWindowResize hook gets the height of the whole document, which we can use to size this block of cuts
  // we could also think about using just something like "10000" so we don't have to update the size when the document changes height
  const { documentHeight } = useWindowResize();

  return (
    <A.Absolute
      zIndex={0}
      top={0}
      left={0}
      width={1}
      height={documentHeight > 0 ? documentHeight : 5000}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.white} points={pts} />
        </Box>
      </Box>
      <Box width={1} flex={1} bg={theme.colors.white} />
    </A.Absolute>
  );
}

BG.displayName = `AboutTheAgencyBlock_BG`;

function AboutTheAgencyBlock({
  data: { agencyCallout, agencyHeading, agencyParagraphNode, agencyImage },
  // backImage,
  // frontImage,
  theme,
  ref,
  parallax,
  inView,
}) {
  // const beam = useSpring({
  //   delay: 1000,
  //   from: {
  //     clipPath: 'polygon(100% 100%, -200% 100%, 100% 80%)',
  //     WebkitClipPath: 'polygon(100% 100%, -200% 100%, 100% 80%)',
  //   },
  //   clipPath: inView
  //     ? 'polygon(-40% 40%, 0 -30%, 100% 80%)'
  //     : 'polygon(100% 100%, -200% 100%, 100% 80%)',
  //   WebkitClipPath: inView
  //     ? 'polygon(-40% 40%, 0 -30%, 100% 80%)'
  //     : 'polygon(100% 100%, -200% 100%, 100% 80%)',
  // });

  // const fade = useSpring({
  //   delay: 1100,
  //   from: { opacity: 0 },
  //   opacity: inView ? 1 : 0,
  // });

  return (
    <Section
      ref={ref}
      pt={theme.sectionPadding.y.map((y, idx) => y + CUT_HEIGHT[idx])}
      pb={[30, 30, 0]}
    >
      <BG theme={theme} scrollTop={parallax.scrollTop} />
      <Container zIndex={1}>
        <Row>
          <Column width={5 / 6} ml={`${(1 / 6) * 100}%`} mb={50}>
            <LineHeading
              label={agencyCallout}
              direction="rtl"
              offsetY={0.025}
            />
          </Column>
          <Column
            width={[`100vw`, `100vw`, 2 / 3]}
            ml={[`50%`, `50%`, -3, -4, -5]}
            mr={[0, 0, -3, -4, -5]}
            transform={[
              `translate3d(-50vw,0,0)`,
              `translate3d(-50vw,0,0)`,
              `none`,
            ]}
            px={0}
          >
            <ParallaxChild width={1} offsetY={0.05}>
              {/* <Absolute height="100%" width={1} top="0" left="0">
                <Img {...backImage.childImageSharp} alt="Toronto skyline" />
              </Absolute>
              <A.Absolute
                bg="rgba(255, 250, 0, 0.7)"
                height="100%"
                width={1}
                top="0"
                left="0"
                style={{
                  backfaceVisibility: `hidden`,
                  ...beam,
                  ...fade,
                }}
              /> */}
              <Img {...agencyImage} />
            </ParallaxChild>
          </Column>
          <ParallaxChild
            as={Column}
            width={[1, 1, 5 / 12]}
            ml={[0, 0, `${-(1 / 12) * 100}%`]}
            mt={[30, 30, 50, 75]}
            offsetY={0.075}
          >
            <H2 pr={`${(0.8 / 5) * 100}%`} fontSize={[21, 24, 28, 32, 38]}>
              {agencyHeading}
            </H2>
          </ParallaxChild>
          <ParallaxChild
            as={Column}
            width={[1, 1, 1, 9 / 12]}
            ml={[0, 0, 0, `${(2 / 12) * 100}%`]}
            mt={[25, 30, 50]}
            offsetY={0.125}
          >
            <H3
              fontSize={[18, 21, 24, 26, 28]}
              dangerouslySetInnerHTML={{
                __html: agencyParagraphNode.childMarkdownRemark.html,
              }}
            />
          </ParallaxChild>
        </Row>
      </Container>
    </Section>
  );
}

export default withTheme(withParallaxProvider(AboutTheAgencyBlock));
