import LineHeading from 'components/LineHeading';
import { ParallaxChild, ParallaxContainer } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Column, Container, Row, Section } from 'system/layout';
import css from '@styled-system/css';
import { h3 } from 'theme/typography';
import { Text } from 'system';

function AboutOurCapabilities({
  data: { capabilitiesCallout, capabilitiesListNode },
}) {
  return (
    <ParallaxContainer as={Section} mt={40}>
      <Container>
        <Row>
          <ParallaxChild as={Column} width={1} ml={0} mb={[30, 30, 40]}>
            <LineHeading label={capabilitiesCallout} direction={`rtl`} />
          </ParallaxChild>
        </Row>
        <Row>
          <ParallaxChild as={Column} width={1} mb={50}>
            <Text
              as={`article`}
              width={1}
              {...h3}
              css={css({ columnCount: [1, 1, 2], lineHeight: [1.3] })}
              dangerouslySetInnerHTML={{
                __html: capabilitiesListNode.childMarkdownRemark.html,
              }}
            />
          </ParallaxChild>
        </Row>
      </Container>
    </ParallaxContainer>
  );
}

export default withTheme(AboutOurCapabilities);
