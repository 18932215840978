import React from 'react';
import { withTheme } from 'styled-components';
// import { useTrail, animated } from 'react-spring';

const MathSigns = ({ title, fill, theme, ...props }) => {
  // const [trail, set] = useTrail(3, () => ({
  //   config: { mass: 2, tension: 200, friction: 25 },
  //   opacity: 1,
  //   from: { opacity: 1 },
  //   onRest: ({ opacity }) => {
  //     set(() => ({
  //       opacity: opacity === 1 ? 0 : 1,
  //     }));
  //   },
  // }));

  return (
    <svg width="100%" height="100%" viewBox="0 0 63.182 62.234" {...props}>
      <title>{title}</title>
      <g fill={fill || theme.colors.blue}>
        <path d="M54.701 16.547H37.739l-8.48 14.687 8.48 14.688h16.962l8.48-14.688zM8.48 32.857L0 47.545l8.48 14.688h16.959l8.481-14.688-8.481-14.688zM25.439 29.376l8.481-14.688L25.439 0H8.478l-8.48 14.688 8.48 14.688z" />
      </g>
      <g fill={fill || theme.colors.white}>
        <path
          d="M16.05 9.811h1.821v9.752H16.05z"
          // style={{ ...trail[0] }}
        />
        <path
          d="M12.084 13.777h9.753v1.821h-9.753z"
          // style={{ ...trail[0] }}
        />
      </g>
      <path
        fill={fill || theme.colors.white}
        // style={{ ...trail[2] }}
        d="M12.084 46.634h9.753v1.821h-9.753z"
      />
      <g fill={fill || theme.colors.white}>
        <path
          d="M41.344 28.345h9.753v1.821h-9.753zM41.344 32.306h9.753v1.821h-9.753z"
          // style={{ ...trail[1] }}
        />
      </g>
    </svg>
  );
};
export default withTheme(MathSigns);
