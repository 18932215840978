import React, { useEffect, useRef, useState } from 'react';
import { Box } from 'system/animated';
import { animated, useSpring, config } from 'react-spring';
import { withTheme } from 'styled-components';

function Arrow({
  title,
  fill,
  theme,
  style = {},
  autoplay = false,
  delay = 0,
  timeout = 0,
  ...rest
}) {
  const delayRef = useRef(delay);
  const delayTimeoutRef = useRef(0);
  const timeoutRef = useRef(0);
  const [animating, setAnimating] = useState(autoplay && delay === 0);
  const isAnimating = useRef(autoplay && delay === 0);

  const [spring, set] = useSpring(() => ({
    config: {
      ...config.slow,
      friction: 60,
      tension: 150,
      mass: 10,
      clamp: true,
      precision: 4,
    },
    y: 0,
    onRest: ({ y }) => {
      set({
        y: y === -105 ? 100 : isAnimating.current ? -105 : 0,
        immediate: y === -105 ? true : false,
      });
    },
  }));

  useEffect(() => {
    isAnimating.current = animating;
    if (animating && timeout > 0) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setAnimating(false);
      }, timeout);
    }
  }, [animating, timeout]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(delayTimeoutRef.current);
    };
  }, []);

  if (!autoplay && delayRef.current > 0) {
    delayTimeoutRef.current = setTimeout(() => {
      isAnimating.current = true;
      setAnimating(true);
      set({
        y: -105,
      });
    }, delayRef.current);
    delayRef.current = 0;
  }

  return (
    <Box style={{ ...style, overflow: `hidden` }} height={`0.75em`} {...rest}>
      <animated.svg
        width="1em"
        height="1em"
        viewBox="0 0 38.816 44.361"
        style={{
          transform: spring.y.to(y => `translate3d(0, ${y}%, 0)`),
        }}
      >
        <title>{title}</title>
        <path
          d="M19.409 0L.001 19.408h11.09v24.953h16.635V19.408h11.09z"
          fill={fill || theme.colors.blue}
        />
      </animated.svg>
    </Box>
  );
}

export default withTheme(Arrow);
