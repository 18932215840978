import AboutHeroBlock from 'blocks/AboutHeroBlock';
import AboutOurCapabilities from 'blocks/AboutOurCapabilities';
import AboutTheAgencyBlock from 'blocks/AboutTheAgencyBlock';
import AboutValuesAndBeliefsBlock from 'blocks/AboutValuesAndBeliefsBlock';
import Footer from 'components/Footer';
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {withGlobals} from 'globals/index';
import useBreakpoints from 'hooks/useBreakpoints';
import React, {useCallback, useEffect, useRef} from 'react';
import {withTheme} from 'styled-components';
import theme from 'theme';

const HERO_CUT_HEIGHT = [350, 550, 650, 650];

function AboutPage({
	                   theme,
	                   footerData,
	                   logoColor,
	                   setLogoColor,
	                   data,
	                   setPageReady,
	                   useNavOpen,
                   }) {
	const {
		heroTagline,
		agencyCallout,
		agencyHeading,
		agencyImage,
		agencyParagraphNode,
		valuesBeliefsCallout,
		valuesBeliefsListing,
		capabilitiesListNode,
		capabilitiesCallout,
		locale,
	} = data.page;

	const navOpen = useNavOpen(state => state.navOpen);
	const isNavOpen = useRef(navOpen);

	useEffect(() => {
		setLogoColor(theme.colors.white);
		setPageReady(true);
	}, [theme.colors.white, setLogoColor, setPageReady]);

	// check logo on an interval
	const [, bp] = useBreakpoints();
	const checkLogo = useCallback(() => {
		const threshold =
			HERO_CUT_HEIGHT[bp] || HERO_CUT_HEIGHT[HERO_CUT_HEIGHT.length - 1];

		if (isNavOpen.current || window.pageYOffset > threshold) {
			setLogoColor(theme.colors.blue);
		} else {
			setLogoColor(theme.colors.white);
		}
	}, [bp, setLogoColor, theme.colors.blue, theme.colors.white]);

	useEffect(() => {
		window.removeEventListener('scroll', checkLogo);
		window.addEventListener('scroll', checkLogo);
		return () => {
			window.removeEventListener('scroll', checkLogo);
		};
	}, [checkLogo]);

	useEffect(() => {
		isNavOpen.current = navOpen;
	}, [navOpen]);

	return (
		<>
			<HelmetDatoCms seo={data.page.seoMetaTags}/>
			<AboutHeroBlock
				html={
					locale === `fr`
						? `Soutenu par les données,[bars]<br/>[arrow]renforcé par<br class="mobile-only"> la créativité et<br class="mobile-only"> exécuté avec passion[star]`
						: `Backed by data,[bars]<br/>[arrow]strengthened<br class="mobile-only"> by creativity, executed<br class="mobile-only"> with passion [star]`
				}
				heroTagline={heroTagline}
			/>
			<AboutTheAgencyBlock
				data={{
					agencyCallout,
					agencyHeading,
					agencyImage,
					agencyParagraphNode,
				}}
				backImage={data.toBackImage}
				frontImage={data.toFrontImage}
			/>
			<AboutValuesAndBeliefsBlock
				data={{valuesBeliefsCallout, valuesBeliefsListing}}
			/>
			<AboutOurCapabilities
				data={{capabilitiesListNode, capabilitiesCallout}}
			/>
			<Footer data={footerData}/>
		</>
	);
}

AboutPage.bgColor = theme.colors.blue;

export const query = graphql`
  query($locale: String!) {
    page: datoCmsAboutPage(locale: { eq: $locale }) {
      id
      locale
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      heroTagline
      agencyCallout
      agencyParagraphNode {
        childMarkdownRemark {
          html
        }
      }
      agencyImage {
        url
        alt
        fluid(maxWidth: 886, imgixParams: { auto: "format,compress", q: 90 }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      valuesBeliefsCallout
      valuesBeliefsListing {
        heading
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        id
        icon
      }
      agencyHeading
      capabilitiesCallout
      capabilitiesListNode {
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;

export default withGlobals(withTheme(AboutPage));
