import React from 'react';
import { h1, h2, h3, h4, h5, h6 } from 'theme/typography';
import { Heading } from 'system';

export const H1 = ({ ...props }) => <Heading as={`h1`} {...h1} {...props} />;
export const H2 = ({ ...props }) => <Heading as={`h2`} {...h2} {...props} />;
export const H3 = ({ ...props }) => <Heading as={`h3`} {...h3} {...props} />;
export const H4 = ({ ...props }) => <Heading as={`h4`} {...h4} {...props} />;
export const H5 = ({ ...props }) => <Heading as={`h5`} {...h5} {...props} />;
export const H6 = ({ ...props }) => <Heading as={`h6`} {...h6} {...props} />;
