import React from 'react';
// import { to, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { Box } from 'system/animated';

function Gear({ title, fill, theme, ...props }) {
  // const [rotateSpring, setRotate] = useSpring(() => ({
  //   config: {
  //     duration: 2000,
  //   },
  //   r: 100,
  //   onRest: ({ r }) => {
  //     setRotate({
  //       r: r === 0 ? 100 : r + 100,
  //     });
  //   },
  // }));

  // const [scaleSpring, setScale] = useSpring(() => ({
  //   s: 1.1,
  //   onRest: ({ s }) => {
  //     setScale({
  //       s: s === 1.1 ? 1 : 1.1,
  //     });
  //   },
  // }));

  return (
    <Box
      width="100%"
      height="100%"
      // style={{
      //   transformOrigin: `50% 50%`,
      //   transform: to(
      //     [rotateSpring.r, scaleSpring.s],
      //     (r, s) => `rotate(${r}deg) scale(${s})`
      //   ),
      // }}
      {...props}
    >
      <svg
        width={`100%`}
        height={`100%`}
        style={{ display: `block` }}
        viewBox="0 0 60 57.064"
      >
        <title>{title}</title>
        <path
          d="M60 28.532l-10.979-6.18 5.249-11.453-12.514 1.453L39.27 0 30 8.532 20.729 0l-2.49 12.352-12.515-1.453 5.25 11.453-10.979 6.18 10.979 6.18-5.25 11.454 12.515-1.454 2.485 12.352 9.271-8.532 9.27 8.532 2.486-12.352 12.514 1.454-5.249-11.454zm-23.34 2.164l-2.883.58.339 2.922-2.673-1.226L30 35.535l-1.443-2.563-2.673 1.226.339-2.922-2.883-.58 1.991-2.164-1.991-2.164 2.883-.58-.339-2.921 2.673 1.225L30 21.529l1.443 2.563 2.673-1.225-.339 2.921 2.883.58-1.992 2.164z"
          fill={fill || theme.colors.blue}
        />
      </svg>
    </Box>
  );
}

export default withTheme(Gear);
