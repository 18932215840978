import React from 'react';
import { withTheme } from 'styled-components';
// import { useSpring, animated } from 'react-spring';

const Rocket = ({ title, fill, theme, ...props }) => {
  // const [spring, setSpring] = useSpring(() => ({
  //   delay: 3000,
  //   config: {
  //     mass: 1,
  //     friction: 2,
  //     velocity: 200,
  //     tension: 300,
  //     precision: 0.1,
  //   },
  //   transform: 'rotate(1deg)',
  //   from: { transform: 'rotate(-1deg)' },
  //   onRest: ({ transform }) => {
  //     setSpring({
  //       transform:
  //         transform === 'rotate(1deg)' ? 'rotate(-1deg)' : 'rotate(1deg)',
  //     });
  //   },
  // }));

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58.546 89.767"
      // style={spring}
      {...props}
    >
      <title>{title}</title>
      <g fill={fill || theme.colors.blue}>
        <path d="M41.734 42.567l-5.937 21.588 9.399 3.797 1.97-8.39zM20.126 33.836L9.399 53.49 0 49.692l4.412-7.404z" />
        <path d="M44.054 5.855L22.3 17.974 10.252 58.55l21.51 8.691 19.52-37.558zM30.977 38.22a5.17 5.17 0 1 1 6.73-2.857 5.169 5.169 0 0 1-6.73 2.857zM22.322 65.676l-4.886-1.974-8.367 6.64 1.87 16.46L23.72 76.26z" />
      </g>
    </svg>
  );
};

export default withTheme(Rocket);
