import React from 'react';
import { withTheme } from 'styled-components';
// import { useSpring, animated, to } from 'react-spring';

const Vine = ({ title, fill, theme, ...props }) => {
  // const [transformSpring, setTransform] = useSpring(() => ({
  //   config: { duration: 3000, clamp: true },
  //   r: -100,
  //   from: { r: 100 },
  //   onRest: ({ r }) => {
  //     setTransform({
  //       r: r === 100 ? -100 : 100,
  //       immediate: r === -100 ? true : false,
  //     });
  //   },
  // }));
  // const [transformSpring2, setTransform2] = useSpring(() => ({
  //   delay: 1500,
  //   config: { duration: 3000, clamp: true },
  //   r: -100,
  //   from: { r: 100 },
  //   onRest: ({ r }) => {
  //     setTransform2({
  //       r: r === 100 ? -100 : 100,
  //       immediate: r === -100 ? true : false,
  //     });
  //   },
  // }));

  // const [scaleSpring, setScale] = useSpring(() => ({
  //   config: { duration: 3000, clamp: true },
  //   s: 1.2,
  //   from: { s: 0 },
  //   onRest: ({ s }) => {
  //     setScale({
  //       s: s === 0 ? 1.2 : 0,
  //       immediate: s === 1.2 ? true : false,
  //     });
  //   },
  // }));

  // const [scaleSpring2, setScale2] = useSpring(() => ({
  //   delay: 1500,
  //   config: { duration: 3000, clamp: true },
  //   s: 1.2,
  //   from: { s: 0 },
  //   onRest: ({ s }) => {
  //     setScale2({
  //       s: s === 0 ? 1.2 : 0,
  //       immediate: s === 1.2 ? true : false,
  //     });
  //   },
  // }));

  return (
    <svg width="100%" height="100%" viewBox="0 0 51.278 61.952" {...props}>
      <title>{title}</title>
      <g fill={fill || theme.colors.blue}>
        <path d="M22.943 0h5.149v61.952h-5.149z" />
        <path
          fill={theme.colors.blue}
          // style={{
          //   transformOrigin: "50% 50%",
          //   transform: to(
          //     [transformSpring.r, scaleSpring.s],
          //     (r, s) => `translate3d(0, ${r}%, 0) scale(${s})`
          //   )
          // }}
          d="M31.763 5.927l-6 19.131 19.517-4.604 6-19.131zM31.763 35.581l-6 19.131 19.515-4.605 6-19.131zM19.516 20.332l6 19.131-19.515-4.606-6-19.131z"
        />
        <path
          fill={theme.colors.blue}
          // style={{
          //   transformOrigin: "50% 50%",
          //   transform: to(
          //     [transformSpring2.r, scaleSpring2.s],
          //     (r, s) => `translate3d(0, ${r}%, 0) scale(${s})`
          //   )
          // }}
          d="M31.763 5.927l-6 19.131 19.517-4.604 6-19.131zM31.763 35.581l-6 19.131 19.515-4.605 6-19.131zM19.516 20.332l6 19.131-19.515-4.606-6-19.131z"
        />
      </g>
    </svg>
  );
};

export default withTheme(Vine);
