import React from 'react';
import { withTheme } from 'styled-components';
// import { useSpring, animated } from 'react-spring';

const Wing = ({ title, fill, theme, ...props }) => {
  // const spring = useSpring({
  //   delay: 3000,
  //   config: { friction: 0 },
  //   transform: 'rotate(-2deg)',
  //   from: { transform: 'rotate(2deg)' },
  // });

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 75 33.165"
      // style={{ ...spring, transformOrigin: "100% 50%" }}
      {...props}
    >
      <title>{title}</title>
      <g fill={fill || theme.colors.blue}>
        <path d="M65.779 12.428H8.296L-.004.001h63.017L75 16.819z" />
        <path d="M69.219 24.226H25.01l-6.693-9.662H65.9l8.923 4.268zM61.794 33.165h-15.6l-4.2-6.816h26.112z" />
      </g>
    </svg>
  );
};

export default withTheme(Wing);
