import AnimatedBars from 'components/icons/AnimatedBars';
import Arrow from 'components/icons/Arrow';
import Gear from 'components/icons/Gear';
import Star from 'components/icons/Star';
import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import * as A from 'system/animated';
import { Box, Text } from 'system';
import { ParallaxContainer, ParallaxChild } from 'hooks/useParallax';
import { Container, Row, Column } from 'system/layout';

function shortcodes(text, theme) {
  const replacePiece = (piece, idx) => {
    switch (piece) {
      case `bars`:
        return (
          <AnimatedBars
            delay={1000}
            timeout={3000}
            autoplay={false}
            key={`aboutheading-${idx}`}
            style={{ verticalAlign: `baseline` }}
          />
        );
      case `arrow`:
        return (
          <Arrow
            delay={4000}
            timeout={3000}
            autoplay={false}
            fill={theme.colors.yellow}
            key={`aboutheading-${idx}`}
            mr={[`5px`, `5px`, 10]}
            style={{ verticalAlign: `baseline` }}
          />
        );
      case `star`:
        return (
          <Star
            delay={8000}
            timeout={3000}
            autoplay={false}
            fill={theme.colors.yellow}
            key={`aboutheading-${idx}`}
            ml={[`5px`, `5px`, 10]}
            style={{ verticalAlign: `baseline` }}
          />
        );
      case `gear`:
        return (
          <Gear
            fill={theme.colors.yellow}
            key={`aboutheading-${idx}`}
            ml={[`5px`, `5px`, 10]}
            style={{ verticalAlign: `baseline` }}
          />
        );
      case `br`:
        return (
          <span
            key={`aboutheading-${idx}`}
            dangerouslySetInnerHTML={{ __html: `<br/>` }}
          />
        );
      default:
        return (
          <span
            key={`aboutheading-${idx}`}
            dangerouslySetInnerHTML={{ __html: piece }}
          />
        );
    }
  };

  text = text.replace(/\[.*?\]/g, match => {
    const code = match.replace(/\[|\]/g, '');
    return `|${code}|`;
  });
  text = text.replace(/<br\s*\/?>/gi, `|br|`);
  const aText = text.split(`|`).filter(Boolean);
  return aText.map((piece, idx) => replacePiece(piece, idx));
}

function AboutHeroBlock({ theme, html, heroTagline, ...rest }) {
  const decodedText = useMemo(() => shortcodes(html, theme), [html, theme]);
  return (
    <ParallaxContainer pt={[120, 120, 130]}>
      <Container>
        <Row justifyContent={`center`}>
          <Column width={1} mt={[35, 55, 75]}>
            <ParallaxChild
              as={A.Text}
              width={1}
              offsetY={0.1}
              color={theme.colors.yellow}
              fontSize={[24, 32, 36]}
              lineHeight={1.4}
            >
              <Box
                width={[1, 1, 3 / 4, 3 / 4, 2 / 3]}
                mx={[0, 0, 0, 0, `${(1 / 6) * 100}%`]}
              >
                <Text
                  as={`h2`}
                  color={theme.colors.white}
                  fontSize={21}
                  style={{ textTransform: `uppercase` }}
                  display={[`none`, `none`, `inline-block`]}
                >
                  {heroTagline}
                </Text>
                <Text
                  as={`h1`}
                  fontFamily={theme.fonts.headings}
                  fontWeight={400}
                  fontSize={[30, 36, 48, 54]}
                  {...rest}
                >
                  {decodedText}
                </Text>
              </Box>
            </ParallaxChild>
          </Column>
        </Row>
      </Container>
    </ParallaxContainer>
  );
}

export default withTheme(AboutHeroBlock);
